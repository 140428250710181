function selectCard(n, amount) {
    var card = document.getElementsByClassName("card");
    var btn = document.getElementsByClassName("button-cta")[0];
    if (document.documentElement.clientWidth > 992)
        var input = document.getElementsByTagName("input")[1];
    else
        var input = document.getElementsByTagName("input")[0];
    if (document.documentElement.clientWidth > 992)
        n = n + 4
    
    for (var i = 0; i < 8; i++) {
        card[i].classList.remove("active");
    }
    card[n].classList.add("active");
    if (amount == 0) {
        var output = parseInt(input.value);
        if (window.location.search === "") {
            btn.href = "https://don.lejourduseigneur.com/avent21/?from=LPAVT21&amount=" + output;
        } else {
            btn.href = "https://don.lejourduseigneur.com/avent21/"+ window.location.search + "&amount=" + output ;
        }
        output = output - output * 66 / 100;
        if (!isNaN(output)) {
            output = Math.round(output);
            console.log(document.getElementById("sum1"));
            console.log(document.getElementById("sum"));
            document.getElementById("sum").innerHTML = output + "€";
            document.getElementById("sum1").innerHTML = output + "€";
        } else {
            document.getElementById("sum").innerHTML = "0€";
            document.getElementById("sum1").innerHTML = "0€";
        }
    } else {
        if (window.location.search === "") {
            btn.href = "https://don.lejourduseigneur.com/avent21/?from=LPAVT21&amount=" + amount;
        } else {
            btn.href = "https://don.lejourduseigneur.com/avent21/" +  window.location.search + "&amount=" + amount;
        }
    }
}

var input = document.getElementsByTagName("input")[0];
var btn = document.getElementsByClassName("button-cta")[0];