window.addEventListener("load", function(event) {
    const queryString = window.location.search;
    var btn = document.getElementsByTagName("a");
    
    // for (var i = 0; i < btn.length; i++) {
    //     if (queryString === "")
    //         btn[i].setAttribute('href', btn[i].getAttribute('href'));
    //     else {
    //         if (isParam("from",  btn[i].getAttribute('href')) == false) {
    //             btn[i].setAttribute('href', btn[i].getAttribute('href') + "&from=LPAVT21");
    //         } else {
    //             btn[i].setAttribute('href', removeParam("from", btn[i].getAttribute('href')) +"&from=LPAVT21");
    //         }
    //     }
    // }
});

function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function updateURL() {
    const queryString = window.location.search;
    var btn = document.getElementsByTagName("a");
    const urlParams = new URLSearchParams(queryString);

    console.log(btn.length);
    for (var i = 0; i < 6; i++) {
        if (queryString === "")
            btn[i].setAttribute('href', btn[i].getAttribute('href'));
        else {
            console.log(urlParams.toString());
            if (!urlParams.has("from")) {
                btn[i].setAttribute('href', btn[i].getAttribute('href') + "&" + queryString.substring(1));
            } else {
                if (btn[i].getAttribute('href').includes("from")) {
                    console.log(btn[i].getAttribute('href').replace("?from", "?") + (urlParams.toString()));
                    btn[i].setAttribute('href', btn[i].getAttribute('href').replace("?from=LPAVT21", "?" + urlParams.toString()));
                }
            }
        }
    }
}

setTimeout(function () {
    updateURL();
}, 1000);

function removeParam(key, sourceURL) {
    var rtn = sourceURL.split("?")[0],
        param,
        params_arr = [],
        queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
    if (queryString !== "") {
        params_arr = queryString.split("&");
        for (var i = params_arr.length - 1; i >= 0; i -= 1) {
            param = params_arr[i].split("=")[0];
            if (param === key) {
                params_arr.splice(i, 1);
            }
        }
        if (params_arr.length) rtn = rtn + "?" + params_arr.join("&");
    }
    console.log("HERE");
    return rtn;
}

function isParam(key, sourceURL) {
    var rtn = sourceURL.split("?")[0],
        param,
        params_arr = [],
        queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
    if (queryString !== "") {
        params_arr = queryString.split("&");
        for (var i = params_arr.length - 1; i >= 0; i -= 1) {
            param = params_arr[i].split("=")[0];
            if (param === key) {
                return (true);
            }
        }
        if (params_arr.length) rtn = rtn + "?" + params_arr.join("&");
    }
    return false;
}