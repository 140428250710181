function selectDate(n) {
    var item = document.getElementsByClassName("date-list-item");
    var page = document.getElementsByClassName("page");

    for (var i = 0; i < 4; i++) {
        item[i].classList.remove("active");
        page[i].classList.remove("active");
    }
    item[n].classList.add("active");
    page[n].classList.add("active");
    changeDate(n);
}

function changeDate(n) {
    var date = [
        {
            'title2': 'DU&nbsp;COURAGE',
            
            'text1' : 'La messe sera célébrée depuis <b>l’église Saint-Aldric du Mans dans la Sarthe</b> en présence de l’association Le&nbsp;Grain&nbsp;de&nbsp;blé. ',
            'text1b' : 'La messe sera célébrée depuis <b>l’église Saint-Aldric du Mans dans la Sarthe</b> en présence de l’association Le&nbsp;Grain&nbsp;de&nbsp;blé. ',
            'text2' : "Cette émission sera consacrée aux personnes en situation de handicap. L’invité du magazine sera Tanguy de la Forest. Champion de tir, il a participé aux jeux paralympiques. L’église du Mans encourage le partage entre gens valides et personnes en situation de handicap.",
            'credit' : '© Sergey Kohl / Shutterstock'
        },
        {
            'title2': 'DE&nbsp;LA&nbsp;RUE',
            'text1' : 'La messe sera célébrée dans la <b>paroisse de Saint-Hippolyte à Paris (XIIIème)</b> qui s’occupe d’accueillir les pauvres.',
            'text1b' : 'La messe sera célébrée dans la <b>paroisse de Saint-Hippolyte à Paris (XIIIème)</b> qui s’occupe d’accueillir les pauvres.',
            'text2' : 'Cette émission sera consacrée aux gens de la rue. Votre magazine ira à la rencontre de personnes en situation de précarité. Comme nous y convie notre pape François, il s’agit de se soucier de ceux qui sont aux périphéries de nos sociétés.',
            'credit' : '© kallejipp/photocase'
        },
        {
            'title2': 'DE&nbsp;L’ESPÉRANCE',
            'text1' : 'La messe sera diffusée depuis <b>l’église Sainte Croix à Rixensart, en Belgique.</b>',
            'text1b' : 'La messe sera diffusée depuis <b>l’église Sainte Croix à Rixensart, en Belgique.</b>',
            'text2' : 'Notre magazine ira à la rencontre des jeunes des Apprentis d’Auteuil. Nous rencontrerons des jeunes aux parcours de vie déroutés. Nous verrons comment ils se mettent en action face à l’adversité.',
            'credit' : '© monkeybusinessimages/Istock'
        },
        {
            'title2': 'EN&nbsp;PRISON',
            'text1' : 'La messe sera célébrée au <b>couvent des Franciscains de Strasbourg.</b>',
            'text1b' : 'La messe sera célébrée au <b>couvent des Franciscains de Strasbourg.</b>',
            'text2' : 'Cette émission recueillera les témoignages d’anciens prisonniers, dont l’un d’eux s’est converti à notre foi pendant sa détention.',
            'credit' : ''
        },
    ]
    
    var bg = document.getElementsByClassName("date")[0];
    var logo = document.getElementsByClassName("date-content-logo")[0];
    var title2 = document.getElementsByClassName("date-content-title2")[0];
    var text1 = document.getElementsByClassName("date-content-text1")[0];
    var text1b = document.getElementsByClassName("date-content-text1")[1];
    var text2 = document.getElementsByClassName("date-content-text2")[0];
    var credit = document.getElementsByClassName("date-credit")[0];
    var i = n + 1;
    
    console.log(document.documentElement.clientWidth);
    if (document.documentElement.clientWidth < 500)
        bg.style.backgroundImage = "url('dist/asset/img/date-bg" + i + "-mb.jpg')";
    else
        bg.style.backgroundImage = "url('dist/asset/img/date-bg" + i + ".jpg')";
    logo.src = "dist/asset/img/date-logo" + i + ".png";
    title2.innerHTML = date[n]['title2'];
    text1.innerHTML = date[n]['text1'];
    text1b.innerHTML = date[n]['text1b'];    
    text2.innerHTML = date[n]['text2'];
    credit.innerHTML = date[n]['credit'];
}

function resize() {
    var bg = document.getElementById("date");
    var page = document.getElementsByClassName("page");

    var n;

    for (var i = 0; i < 4; i++)
        if (page[i].classList.contains("active"))
            n = i;
    if (document.documentElement.clientWidth < 500) {
        bg.setAttribute('background-image', "url('dist/asset/img/date-bg" + n + "-mb.jpg')");
    } else {
        bg.setAttribute('background-image', "url('dist/asset/img/date-bg" + n + ".jpg')");
    }

    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    var navbar = document.getElementById("navigation"); 
    var header = document.getElementById("header");

    
    header.style.marginTop = navbar.offsetHeight + "px";
    header.style.height = vh - navbar.offsetHeight + "px";
}